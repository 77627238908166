<template>
  <div class="profile" :class="localDisplayLang === 'ara' ? 'rtl-profile' : ''" id="profile">
   <div class="dropdown">
  <button class="dropbtn">Dropdown</button>
  <div class="dropdown-content">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { _providerId, _projectName } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";

export default {
  data() {
    return {
      userName: "Ayan Mandal",
      userEmail: "ayan.mandal@mobiotics.com",
      subscriberDetails: null,
      profiles: [],
      loader: false,
      displayPic: require("@/assets/icons/ProfileDp.png"),
      profileError: null,
      noProfiles: true,
      providerUniqueId: _providerId,
      providerName: _projectName,
      localDisplayLang: null,
      profileDetails: null
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "getRtl", "appConfig"]),

    getSubscriberProfileName() {

      if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename && this.subscriberProfileDetails.profilename.length > 15) {
        return `${this.subscriberProfileDetails.profilename.substring(0, 15)}...`;
      } else if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename) {
        return this.subscriberProfileDetails.profilename;
      } else if (this.subscriberDetails.profilename && this.subscriberDetails.profilename.length > 15) {
        return `${this.subscriberDetails.profilename.substring(0, 15)}...`;
      } else {
        return this.subscriberDetails.profilename;
      }
      // if (this.subscriberDetails.data.profilename && this.subscriberDetails.data.profilename.length > 15) {
      //   return `${this.subscriberDetails.data.profilename.substring(0, 15)}...`;
      // } else {
      //   return this.subscriberDetails.data.profilename;
      // }
    },

    checkIfKidsProfile() {
      if(this.profileDetails && this.profileDetails.kidsmode === "YES") {
        return true
      } else {
        return false
      }
    },

    checkIfMultipleProfileEnabled() {
      // isMultipleProfileEnabled
      if(this.appConfig.featureEnabled.isMultipleProfileEnabled) {
        return true;
      }
    }
  },

  watch: {
    subscriberid(val) {
      if (val) {
        this.getSubscriberDetails();
      }
    },
  },

  created() {

    this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails")
        ? JSON.parse(localStorage.getItem("subscriberProfileDetails"))
        : null;

    this.getSubscriberDetails();

    let currentProfileDetails = localStorage.getItem("subscriberProfileDetails");
    if(currentProfileDetails) {
      currentProfileDetails = JSON.parse(currentProfileDetails);

      this.profileDetails = currentProfileDetails;
    }

    

    localStorage.setItem("payment_source", "Profile");

    if (localStorage.getItem("setDisplayLanguageCode")) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    
    }
  },

  beforeDestroy() {
    document.body.style.overflow = "auto";
  },

  mounted() {
    document.body.style.overflow = "hidden";

    // window.addEventListener("localstorage-updated", (event) => {
    //   this.subscriberDetails = JSON.parse(event.detail.storage).data;
    // });

    // eventBus.$on("profile-list-response", (response) => {
    //   if (!response.reason) {
    //     // this.profiles = response.data;

    //     console.log("profile", this.subscriberDetails);

    //     response.data.forEach((element) => {
    //       if (element.profileid !== this.subscriberDetails.profileid) {
    //         this.profiles.push(element);
    //       }
    //     });

    //     this.noProfiles = false;
    //   }
    // });

    // eventBus.$on("switchProfileResponse", (response) => {
    //   if (response.success) {
    //     //logging switch profile google analytics event.
    //     let GA = {
    //       status: "Success",
    //     };
    //     this.switchProfileEvent(GA);

    //     localStorage.setItem("sessionToken", response.success);
    //     let payload = {
    //       app: "pinswitch",
    //     };
    //     eventBus.$emit("subscriberDetails", payload);
    //   }
    // });
  },

  methods: {
    ...mapActions(["actGetLogout"]),
    actRoutingProfile () {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      this.$router.push({name: "Profile", params: { lang: currentLanguage }})
    },
    goToSwitchProfile() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      this.$router.push({name: "switchProfile", params: { lang: currentLanguage }})
    },

    logout() {
      eventBus.$emit("subscriberLogout");
    },

    getSubscriberDetails() {
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails"));
      }
      // eventBus.$emit("list-profiles", "profileDropdown");
    },
    // switchProfile(profile) {
    //   console.log("switch profile", profile);
    //   if (profile.profilepin === "ENABLED") {
    //     eventBus.$emit("switchProfile", profile);
    //   } else {
    //     eventBus.$emit("enableOverlayLoader", true);

    //     let payload = {
    //       profileid: profile.profileid,
    //     };
    //     eventBus.$emit("switch-profile", payload);
    //   }
    // },
    changeColor() {
      this.fillColor = "#ff9600";
      this.opacityVal = "1";
    },
    ChangeOriginal() {
      this.fillColor = "#EFEFF4";
      this.opacityVal = "0.5";
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    SwitchImg: () => import(/* webpackChunkName: "SwitchImg" */ "@/components/SvgImages/SwitchImage.vue"),
  },
  mixins: [googleAnalytics, Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./moreOptions.scss"
</style>
